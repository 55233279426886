<template>
  <section v-editable="blok" class="relative grid w-full grid-cols-1 items-center gap-x-4 gap-y-6 tablet:grid-cols-4 desktop:grid-cols-8">
    <sb-image
      v-if="blok.image?.filename"
      :blok="blok.image"
      :class="blok.reverse ? 'tablet:order-2 desktop:ml-auto' : 'desktop:mr-auto'"
      width="110"
      height="110"
      fit-inside
      decorative
      class="mx-auto rounded desktop:col-span-2"
    />
    <div class="grid grid-cols-1 tablet:col-span-3 desktop:col-span-6">
      <h2 v-if="blok.headline" class="text-xl font-bold">
        {{ blok.headline }}
      </h2>
      <div class="items-start gap-2">
        <div class="inline-images prose prose-lg whitespace-pre-wrap leading-snug dark:prose-invert" v-html="text" />
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import type { TeaserStoryblok } from "~/component-types-sb"

const props = defineProps<{ blok: TeaserStoryblok }>()

const text = renderRTF(props.blok?.text)
</script>
